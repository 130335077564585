<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="permissao('disciplina_abrir')" exact :to="`/disciplina/alterar/${id_disciplina}`" icon class="py-4"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="permissao('disciplina_excluir')" exact @click="del" icon class="py-4"><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn to="/disciplina" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Disciplina:</strong> {{disciplina.disciplina}}</p>
                            <p class="text--primary mb-2"><strong>Status:</strong> {{disciplina.status == 't' ? 'Ativa' : 'Inativa'}}</p>
                            <p class="text--primary mb-2"><strong>ID:</strong> {{disciplina.id_disciplina}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <!--<v-col cols="12">
            <v-tabs v-model="tab" class="elevation-3" show-arrows>
                <v-tab>* Cursos</v-tab>
                <v-tab>* Aulas</v-tab>
                <v-tab>* Alunos</v-tab>
                <v-tab>* Turmas</v-tab>
                <v-tab>* Professores</v-tab>
                <v-tab>* Franquias</v-tab>
                <v-tab>* Vocabulário</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="elevation-3">
                <v-tab-item>
                    <v-divider/>
                    <v-card-text><CursoListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <v-card-text><AulaListar/></v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-card-text><AlunoListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <v-card-text><TurmaListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <v-card-text><UsuarioListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <v-card-text><FranquiaListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                        <v-divider/>
                        <v-card-text>/* Listar palavras buscadas da disciplina em questão */</v-card-text>
                </v-tab-item>
            </v-tabs-items>
        </v-col>-->
    </v-row>
</template>

<script>

/*import CursoListar from "@/Views/Curso/Component/CursoListar"
import AulaListar from "@/Views/Aula/Component/AulaListar"
import AlunoListar from "@/Views/Aluno/Component/AlunoListar"
import TurmaListar from "@/Views/Turma/Component/TurmaListar"
import UsuarioListar from "@/Views/Usuario/Component/UsuarioListar"
import FranquiaListar from "@/Views/Franquia/Component/FranquiaListar"*/
import {mapState} from "vuex"
import axios from "axios"

export default {
    name: "DisciplinaGerenciar",
    // components: {FranquiaListar, UsuarioListar, TurmaListar, AlunoListar, AulaListar, CursoListar},
    props : ['id_disciplina'],
    data() {
        return {
            tab : null,
            disciplina : {}
        }
    },
  computed : {
    ...mapState(['baseUrl'])
  },
  methods : {
    get() {
      return axios.post(`${this.baseUrl}disciplina/get`, {id_disciplina : this.id_disciplina}).then( (res) => {

        this.disciplina = res.data
      })
    },
    del() {
      let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")

      if(c) {
        return axios.post(`${this.baseUrl}disciplina/delete`, {id_disciplina : this.id_disciplina}).then( (res) => {

          if (res.data) {

            this.$router.push({path:'/disciplina'})
          } else {

            alert("Erro ao excluir registro")
          }
        })
      }
    }
  },
  activated() {
    this.get()
  }
}
</script>

<style scoped>

</style>
